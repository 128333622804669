<template>
  <v-container v-if="getLeilao && getLeilao.linkYoutube">
    <v-row>
      <v-col class="text-center">
        <div class="youtube" v-html="getLeilao.linkYoutube"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'transmissao',
  computed: {
    ...mapGetters(['getLeilao']),
  },
};
</script>

<style></style>
